import { memo } from 'react';
import cx from 'classnames';
import { InfoItem, SelectInput, TextInput, onSelectChangeAdapter, selectValueAdapter } from '@intellecteu/common-ui';
import { memory, Labels } from 'app/constants';
import { handleCpuResourcesInput, handleIntegerInput } from 'app/utils/helpers';
import { Field } from '../ui/form/field';

import s from './styles.module.scss';

type ResourcesProps = {
  startName?: string;
  className?: string;
  wrapperClassName?: string;
  subTitle?: string;
  label?: string;
};

const ResourcesComp = ({
  startName,
  className,
  wrapperClassName,
  label = Labels.RESOURCES,
  subTitle,
}: ResourcesProps) => (
  <div className={wrapperClassName}>
    <InfoItem className={s.formSidebarSectionTitle} label={label} labelSize="s" labelColor="dark" labelWeight="bold" />
    {subTitle && <InfoItem className={s.formSidebarSectionTitle} label={subTitle} labelSize="xs" labelColor="dark" />}

    <div className={cx(s.resources, className)}>
      <div className={s.resourcesForm}>
        <Field
          component={TextInput}
          id={`${startName ? `${startName}.` : ''}resources.cpuRequested`}
          name={`${startName ? `${startName}.` : ''}resources.cpuRequested`}
          label={`${Labels.REQUESTED_CPU}*`}
          onKeyPress={handleCpuResourcesInput}
        />
        <Field
          component={TextInput}
          id={`${startName ? `${startName}.` : ''}resources.cpuLimit`}
          name={`${startName ? `${startName}.` : ''}resources.cpuLimit`}
          label={`${Labels.CPU_LIMIT}*`}
          onKeyPress={handleCpuResourcesInput}
        />

        <div className={s.fieldGroup}>
          <Field
            className={s.fieldGroupInput}
            component={TextInput}
            id={`${startName ? `${startName}.` : ''}resources.memoryRequested.value`}
            name={`${startName ? `${startName}.` : ''}resources.memoryRequested.value`}
            label={`${Labels.REQUESTED_MEMORY}*`}
            onKeyPress={handleIntegerInput}
          />
          <Field
            className={s.fieldGroupSelect}
            options={memory}
            component={SelectInput}
            wrapperClassName={s.fieldGroupSelectContainer}
            id={`${startName ? `${startName}.` : ''}resources.memoryRequested.unit`}
            name={`${startName ? `${startName}.` : ''}resources.memoryRequested.unit`}
            onChangeAdapter={onSelectChangeAdapter}
            valueAdapter={selectValueAdapter(memory)}
            maxMenuHeight="200px"
            isMenuAbove
          />
        </div>

        <div className={s.fieldGroup}>
          <Field
            className={s.fieldGroupInput}
            component={TextInput}
            id={`${startName ? `${startName}.` : ''}resources.memoryLimit.value`}
            name={`${startName ? `${startName}.` : ''}resources.memoryLimit.value`}
            label={`${Labels.MEMORY_LIMIT}*`}
            onKeyPress={handleIntegerInput}
          />
          <Field
            className={s.fieldGroupSelect}
            options={memory}
            component={SelectInput}
            wrapperClassName={s.fieldGroupSelectContainer}
            id={`${startName ? `${startName}.` : ''}resources.memoryLimit.unit`}
            name={`${startName ? `${startName}.` : ''}resources.memoryLimit.unit`}
            onChangeAdapter={onSelectChangeAdapter}
            valueAdapter={selectValueAdapter(memory)}
            maxMenuHeight="200px"
            isMenuAbove
          />
        </div>

        <Field
          component={TextInput}
          id={`${startName ? `${startName}.` : ''}resources.replicas`}
          name={`${startName ? `${startName}.` : ''}resources.replicas`}
          label={Labels.REPLICAS}
          onKeyPress={handleIntegerInput}
        />
      </div>
    </div>
  </div>
);

export const Resources = memo(ResourcesComp);
