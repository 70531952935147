/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AppInfoDto } from './AppInfoDto';
import type { ClusterConfigDto } from './ClusterConfigDto';
import type { ClusterParticipantConfigDto } from './ClusterParticipantConfigDto';
import type { EnvVar } from './EnvVar';
import type { ResourcesDto } from './ResourcesDto';
import type { ValidatorAuthDto } from './ValidatorAuthDto';

export type ValidatorDto = {
    clusterConfig: ClusterConfigDto;
    clusterParticipantConfig: ClusterParticipantConfigDto;
    envVars?: Array<EnvVar>;
    imageRepo: string;
    imageTag: string;
    name: string;
    onboardingSecret: string;
    participant: AppInfoDto;
    phase?: ValidatorDto.phase;
    postgresPassword: string;
    postgresUser: string;
    resources?: ResourcesDto;
    customAuth?: boolean;
    // FE: do not delete
    auth?: ValidatorAuthDto | null;
};

export namespace ValidatorDto {

    export enum phase {
        COMPLETED = 'COMPLETED',
        PENDING = 'PENDING',
        RUNNING = 'RUNNING',
    }


}

