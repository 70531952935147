import data from './data.json';

let { url, realm, clientId, authority } = data;
let authKeycloakEnabled: boolean;

if (process.env.NODE_ENV !== 'development') {
  authKeycloakEnabled = window._env_.AUTH_KEYCLOAK_ENABLED ?? true;
  url = `${window._env_.KEYCLOAK_URL}`;
  realm = window._env_.KEYCLOAK_REALM;
  clientId = authKeycloakEnabled ? window._env_.KEYCLOAK_CLIENT_ID : window._env_.AUTH_CLIENT_ID;
  authority = authKeycloakEnabled ? `${window._env_.KEYCLOAK_URL}/auth/realms/${realm}` : `${window._env_.AUTH_URL}`;
} else {
  authKeycloakEnabled = String(import.meta.env.VITE_AUTH_KEYCLOAK_ENABLED) === 'true';
}

export interface INameAuthProps {
  url: string;
  realm: string;
  clientId: string;
  authority: string;
  authKeycloakEnabled: boolean;
}

export const authConfig: INameAuthProps = {
  url: `${url}/auth`,
  realm,
  clientId,
  authority,
  authKeycloakEnabled,
};
