import { TOption } from '@intellecteu/common-ui';
import { MemoryEnum } from 'data';
import { ACTIVE, ALL_HEALTH_STATUSES, ALL_VALUE, INACTIVE } from './dictionary';

export const image = 'digitalasset/canton-open-source:2.7.9';
export const memoryDefault = '1';
export const memoryLimit = '2';
export const cpuLimit = '2';
export const cpuRequested = '1';
export const sizeDefault = '1';
export const backupSizeDefault = '1';
export const maxDurationInSecDefault = 1;
export const retentionInSecDefault = 1;

/** Create Validator:
 appInfoCantonNameService
 */
export const cpuWalletCnsLimit = '1';
export const cpuWalletCnsRequested = '0.1';
export const memoryWalletCnsLimit = '1536';
export const memoryWalletCnsRequested = '240';

export const defaultJvmOptions = '-Xms1152M -Xmx1152M -Dscala.concurrent.context.minThreads=4';
export const minTopupInterval = '1m';
export const targetThroughput = 33330;

export type UnitAdapter = {
  value: string;
  unit: MemoryEnum | keyof typeof MemoryEnum;
};

export const memory = Object.values(MemoryEnum).map((el) => ({ value: el, label: el }));

export const FROM = 0;
export const PAGE_SIZE = 5;

export const allFilterOption = { label: 'All', value: 'All', disabled: false };

export const healthOptions: TOption[] = [
  {
    label: ALL_HEALTH_STATUSES,
    value: ALL_VALUE,
    disabled: false,
  },
  {
    label: ACTIVE,
    value: true,
  },
  {
    label: INACTIVE,
    value: false,
  },
];
