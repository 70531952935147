import { ReactNode, useCallback } from 'react';
import { toast, ToastOptions } from 'react-toastify';

const baseOptions: ToastOptions = {
  position: 'bottom-right',
  draggable: true,
  closeOnClick: false,
  autoClose: 4000,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
  theme: 'light',
};

export const useNotifications = () => {
  const showSuccessMessage = useCallback((message: string | ReactNode, options?: ToastOptions, onOpen?: () => void) => {
    const opts = {
      ...baseOptions,
      ...options,
      autoClose: options?.delay ? 7000 : baseOptions.autoClose,
      onOpen,
    };
    toast.success(message, opts);
  }, []);

  const showErrorMessage = useCallback((message: string | ReactNode, options?: ToastOptions, onOpen?: () => void) => {
    toast.error(message, { ...baseOptions, ...options, onOpen });
  }, []);

  const showWarningMessage = useCallback(
    (message: string | ReactNode, options?: ToastOptions, onOpen?: () => void): void => {
      toast.warning(message, { ...baseOptions, ...options, onOpen });
    },
    [],
  );

  const showInfoMessage = useCallback(
    (message: string | ReactNode, options?: ToastOptions, onOpen?: () => void): void => {
      const opts = {
        ...baseOptions,
        ...options,
        autoClose: options?.delay ? 7000 : baseOptions.autoClose,
        onOpen,
      };
      toast.info(message, opts);
    },
    [],
  );

  const dismiss = useCallback((): void => {
    toast.dismiss();
  }, []);

  // Feature specific notifications

  // PRUNING
  const showPruningScheduledMessage = (name: string) =>
    showSuccessMessage(
      <div>
        Pruning for <strong>{name}</strong> was scheduled
      </div>,
    );

  const showPruningRemovedMessage = (name: string) =>
    showSuccessMessage(
      <div>
        Pruning for <strong>{name}</strong> was removed
      </div>,
    );

  // BACKUPS
  const showBackupScheduledMessage = (name: string) =>
    showSuccessMessage(
      <div>
        Backup for <strong>{name}</strong> was scheduled
      </div>,
    );

  const showBackupRemovedMessage = (name: string) =>
    showSuccessMessage(
      <div>
        Backup schedule for <strong>{name}</strong> was removed
      </div>,
    );

  // PARTIES
  const showPartyCreatedMessage = (name: string) =>
    showSuccessMessage(
      <div>
        Party for <strong>{name}</strong> was created
      </div>,
    );

  // USERS
  const showUserCreatedMessage = (name: string) =>
    showSuccessMessage(
      <div>
        User <strong>{name}</strong> was created
      </div>,
    );

  const showRightsUpdatedMessage = (name: string) =>
    showSuccessMessage(
      <div>
        <strong>{name}</strong>`s rights were updated
      </div>,
    );

  // FILES
  const showFileUploadedMessage = (name: string) =>
    showSuccessMessage(
      <div>
        File <strong>{name}</strong> was uploaded
      </div>,
    );

  return {
    dismiss,
    showSuccessMessage,
    showErrorMessage,
    showWarningMessage,
    showInfoMessage,
    showPruningScheduledMessage,
    showPruningRemovedMessage,
    showBackupScheduledMessage,
    showBackupRemovedMessage,
    showPartyCreatedMessage,
    showUserCreatedMessage,
    showRightsUpdatedMessage,
    showFileUploadedMessage,
  };
};
